<div class="e-card" id="basic">
  <div class="card-content">
      <div class="e-card-header">
          <div class="e-card-header-caption">
              <div class="e-card-title">Test page</div>
          </div>
      </div>
      <div class="e-card-content" id="content">
          <ejs-grid [dataSource]='data' [allowPaging]="true" [allowSorting]="true" [allowFiltering]="true" [pageSettings]='pageSettings'>
              <e-columns>
                  <e-column field='OrderID' headerText='Order ID' textAlign='Right' width=90></e-column>
                  <e-column field='CustomerID' headerText='Customer ID' width=120></e-column>
                  <e-column field='Freight' headerText='Freight' textAlign='Right' format='C2' width=90></e-column>
                  <e-column field='OrderDate' headerText='Order Date' textAlign='Right' format='yMd' width=120></e-column>
              </e-columns>
          </ejs-grid>
      </div>
  </div>
</div>
