<div id="head">
    <button ejs-button id="toggle" #togglebtn cssClass="e-flat" iconCss="e-icons burg-icon" isToggle="true" (click)="btnClick()" (touchstart)="touchstart()" (touchend)="touchend()" content="Close"></button>
  </div>
  <ejs-sidebar id="default-sidebar" #sidebar [type]='type' [enablePersistence]='enablePersistence' (created)="onCreated($event)" style="visibility: hidden" [target]='target'>
    <ejs-listview id='ui-list' [dataSource]='menuList'>
      <ng-template #template let-data="">
        <div class="list-container" [routerLink] = "[data.url]">
            <i-bs name={{data.icon}}></i-bs>
            <span class="list-item">{{data.name}}</span>
        </div>
      </ng-template>
    </ejs-listview>
  </ejs-sidebar>
  <div id="maincontent" class="content">
    <div class="container-fluid">
      <div class="row"> 
        <div class="col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  