import { AuthConfig } from 'angular-oauth2-oidc';

export const authPasswordFlowConfig: AuthConfig = {
    tokenEndpoint: 'https://www.fenster-henstedt.de/oauth/token',
    clientId: 'my-trusted-client',
    dummyClientSecret: 'secret',
    showDebugInformation: true,
    oidc: false,
    useHttpBasicAuth: true,
    scope: 'read write trust'
};
