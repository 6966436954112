<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>Log in</mat-card-title>
      </mat-card-header>
  
      <form class="example-form" [formGroup]="requestForm" id="requestForm">
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Login" [formControl]="login" type="text">
            <mat-error *ngIf="requestForm.controls['login'].invalid">
              {{getError('login')}}
            </mat-error>
          </mat-form-field>
  
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Password" [formControl]="password" type="password">
            <mat-error *ngIf="!requestForm.controls['password'].valid">
              {{getError('password')}}
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <button ejs-progressbutton content='Log in' class="e-primary" type="submit" [disabled]="!requestForm.valid"></button>
      </form>
    </mat-card>
  
  </div>